import vue from 'vue'
import auth from '@/middleware/auth.js'

export default [
	{
		path: ''
		,component: () => import('@/layouts/Constelacion')
		,children: [
			{
				path: '/servicio_a_clientes'
				,name: 'servicio_a_clientes'
				,component: () => import('@/apps/clientes/pages/Dashboard')
				,meta: {
					title: 'Servicio a clientes'
					,middleware: auth
				}
			}
			,{
				path: '/servicio_a_clientes/solicitudes'
				,name: 'clientes_solicitudes'
				,component: () => import('@/apps/clientes/pages/Solicitud')
				,meta: {
					title: 'Solicitudes pendientes - Servicio a Clientes'
					,middleware: auth
				}
			}
			,{
				path: '/servicio_a_clientes/clientes'
				,name: 'clientes_usuarios'
				,component: () => import('@/apps/clientes/pages/Usuario')
				,meta: {
					title: 'Clientes - Servicio a Clientes'
					,middleware: auth
				}
			}
			,{
				path: '/servicio_a_clientes/prospectos'
				,name: 'prospectos'
				,component: () => import('@/apps/clientes/pages/Prospecto')
				,meta: {
					title: 'Prospectos - Servicio a Clientes'
					,middleware: auth
				}
			}
			,{
				path: '/servicio_a_clientes/admin/catalogos'
				,name: 'clientes_admin_catalogos'
				,component: () => import('@/apps/clientes/pages/Catalogo')
				,meta: {
					title: 'Catálogos - Admin - Servicio a Clientes'
					,middleware: auth
				}
			}
			,{
				path: '/servicio_a_clientes/admin/catalogos/tipos_contacto'
				,name: 'clientes_admin_catalogos_tipos_contactos'
				,component: () => import('@/apps/clientes/pages/TipoContacto')
				,meta: {
					title: 'Tipos Contacto -Catálogos - Admin - Servicio a Clientes'
					,middleware: auth
				}
			}
			,{
				path: '/servicio_a_clientes/admin/accesos_expedientes'
				,name: 'clientes_admin_accesos_expedientes'
				,component: () => import('@/apps/clientes/pages/Acceso')
				,meta: {
					title: 'Acceso a expedientes - Admin - Servicio a Clientes'
					,middleware: auth
				}
			}
			,{
				path: '/servicio_a_clientes/admin/actividades_sistema'
				,name: 'clientes_admin_actividades_sistema'
				,component: () => import('@/apps/clientes/pages/Actividades')
				,meta: {
					title: 'Actividades dentro del sistema - Admin - Servicio a Clientes'
					,middleware: auth
				}
			}
			,{
				path: '/servicio_a_clientes/buzon'
				,name: 'clientes_buzon'
				,component: () => import('@/apps/clientes/pages/Buzon')
				,meta: {
					title: 'Buzón de sugerencias - Servicio a Clientes'
					,middleware: auth
				}
			}
			,{
				path: '/servicio_a_clientes/admin/configuracion'
				,name: 'clientes_configuracion'
				,component: () => import('@/apps/clientes/pages/Configuracion')
				,meta: {
					title: 'Configuración - Admin - Servicio a Clientes'
					,middleware: auth
				}
			}
		]
	}
]